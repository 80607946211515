// There is a similar file on the client side until we build the configuration endpoint.
// Location is: src/client/auth/AuthProvider.tsx
import { ResourcesConfig } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";

export const AwsAmplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      allowGuestAccess: false,
      identityPoolId: "us-east-2:281c4ef4-0281-4b02-90bd-bd06df09040c",
      userPoolClientId: "5qu0iuitemctka9uusnub3q9co",
      userPoolId: "us-east-2_XhqOAlrn4",
      loginWith: {
        oauth: {
          redirectSignIn: ["https://engage-admin.dev.metacommerce.app/"],
          redirectSignOut: ["https://engage-admin.dev.metacommerce.app/"],
          domain: "metacommerce-idp-dev-01.dev.metacommerce.app",
          responseType: "code",
          scopes: ["openid", "email", "profile"],
        },
      },
      signUpVerificationMethod: "code",
    },
  },
  Storage: {
    S3: {
      bucket: "assets.dev.metacommerce.app",
      region: "us-east-2",
    },
  },
};

export const cookieStorageConfig = (): CookieStorage => {
  return new CookieStorage({
    domain: ".dev.metacommerce.app",
    expires: 365,
    path: "/",
    sameSite: "strict",
    secure: true,
  });
};
