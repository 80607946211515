const features = {
  "web3forms_embed_sharing" : {
    "default": "on",
    "845906d1-dcb8-4468-ae31-515a087b5756": "On"
  },
  "studio_feature": {
    "default": "on"
  },
  "studio_deploy_plugins": {
    "default": "on"
  },
  "studio_contract_accesslist": {
    "default": "on"
  },
  "studio_download_asset": {
    "default": "off"
  },
  "studio_versioning": {
    "default": "on"
  },
  "minting_embed": {
    "default": "on"
  },
  "studio_airdrop": {
    "default": "on"
  },
  "synced_contracts": {
    "default": "on"
  },
  "workflows": {
    "default": "on"
  },
  "event_sourcing": {
    "default": "on"
  },
  "identity_settings": {
    "default": "on"
  },
  "identity_stream": {
    "default": "on"
  },
  "unique_identity_settings": {
    "default": "on"
  },
  "unique_identity_stream": {
    "default": "on"
  },
  "inbound_settings": {
    "default": "on"
  },
  "outbound_settings": {
    "default": "on"
  },
  "activity_stream": {
    "default": "on"
  },
  "home_page_v2": {
    "default": "on"
  },
  "mixpanel_outbound": {
    "default": "on"
  },
  "system_settings": {
    "default": "on"
  }
}
export default features;
